import { IAmazonTouchpoint, IEmailBlastTouchpoint, IFacebookTouchpoint, IGoogleTouchpoint, NotificationModel } from ".";

export enum Goals {
    increaseAwareness = 1,
    increaseSales = 2,
    increaseEventSignups = 4,
    increaseSocial = 8,
    websiteVisits = 16,
    other = 32
}

export enum Promotions {
    newRelease = 1,
    existingTitle = 2,
    eventSigning = 4,
    severalTitles = 8,
    author = 16,
    website = 32,
    other = 64
}

export type CampaignPageResponse = {
    content: Array<CardCampaignModel>
    campaignStatus: Array<{ status: number, count: number }>
    currentPage: number
    maxPages: number
    totalCampaigns: number
}

export type CardCampaignModel = {
    id: string
    name: string
    books: Array<BookModel>
    startDate: Date
    endDate: Date
    accountId: string
    status: number
    folderId: null | string
    favorite: boolean
    touchpoints: Array<CardTouchpointItem>
}

export type CardTouchpointItem = {
    id: string
    service: string | number
    clicks: number
    status: number
    startDate: Date
    endDate: Date
}

export type CampaignModel = {
    id: string
    name: string
    startDate: Date
    endDate: Date
    budget: number
    books: Array<BookModel>
    accountId: string
    status: number
    marketingGoals: number
    promoting: number
    favorite: boolean
    recommendedAudiences: RecomendedAudienceModel[]
    facebookTouchpoints: IFacebookTouchpoint[]
    googleTouchpoints: IGoogleTouchpoint[]
    amazonTouchpoints: IAmazonTouchpoint[]
    emailBlastTouchpoints: IEmailBlastTouchpoint[]
    completePercentage: number
    updatedDate: Date
    notifications: NotificationModel[]
    endDateExtendPending: boolean
    statsResume: IStatsResume
    campaignInvoices: InvoiceModel[]
    folderId: null | string
}
export type InvoiceModel = {
    id: string
    campaignID: string
    stripeInvoiceId: string
    stripeCustomerId: string
    promotionCode: string
    zipCode: string
    total: number
    paymentStatus: number
}

export type CampaignFormModel = {
    id: string
    name: string
    startDate: Date | undefined
    endDate: Date | undefined
    budget: number
    books: Array<BookModel>
    accountId: string
    status: number
    marketingGoals: boolean[]
    promoting: boolean[]
    recommendedAudiences: RecomendedAudienceModel[]
    facebookTouchpoints: IFacebookTouchpoint[]
    googleTouchpoints: IGoogleTouchpoint[]
    amazonTouchpoints: IAmazonTouchpoint[]
    emailBlastTouchpoints: IEmailBlastTouchpoint[]
    endDateExtendPending: boolean
    shareAmazonBudget: boolean
}

export type BookModel = {
    id: string
    isbn: string
    title: string
    author: string
    genre: string
    type: string
    pubDate: string
    bookCoverURL: string
    asin: string
    sku: string
    publishedDate: string
    primaryNarrative: string
}

export type RecomendedAudienceModel = {
    id: string
    name: string
    description: string
    locations: string | null
    interest: string | null
    size?: string
    salesforcePopulation: number
    facebookAudienceValidated: number
    facebookPopulation: number
    googleAudienceValidated: boolean
    googleDisplayPopulation: number
    emailBlastPopulation: number
    emailBlastAudienceValidated: boolean
    emailBlastAudienceId: string
    servicesLabel: string
    services: ServiceModel[]
}

export type ServiceModel = {
    id: string
    name: string
    description: string
    type: number
    startingAt: number
    audiences: Array<string>
}

export type ProductModel = {
    id: string
    audienceName: string
    cpa: number
    population: number
    enable: boolean
    productPrices: ProductPriceModel[]
    recommendedAudience: RecomendedAudienceModel
    service: ServiceModel | null
    serviceId: string
    sku: string
}

export type ProductPriceModel = {
    id: string
    budget: number
    clickTarget: string
    enable: boolean
    impressions: string
    price: number
    productId: string
    salesforceId: string
    deleted: boolean
}


export type AmazonKeywordModel = {
    id?: string
    matchType: null | string
    keywordText: string
}

export type FinalKeywordModel = {
    seedKeyword: string
    finalWord: string
    CPC: number
    CMP: number
    MSV: number
}

export type FilterOption = {
    id?: string
    title?: string
    ocurrences?: Array<string>
    name?: string
    campaignId?: string
    group?: string
}

export type TouchpointFormError = {
    id: string
    field: string
    desc: string
    step: number
}

export type GoogleValidationResponseItem = {
    Message: string
    Trace: string
    Code: string
}

export type GoogleValidationItem = {
    trace: string
    field: string
    message: string
    code: string
}

export interface IExtendedCampaignSummaryRequest {
    campaignId: string
    country?: string
    state?: string
    city?: string
    street?: string
    zipCode?: string
    promotionCode?: string
    stripeCustomerId?: string
    touchpointsToExtend?: ITouchpointExtendedRequestItem[]
}

export interface ITouchpointExtendedRequestItem {
    touchpointId: string
    newEndDate: Date
}

export interface IStatsResume {
    aboveTargetClicks: number
    aboveTargetImpressions: number
    clicks: number
    cpc: number
    cpcArray: number[]
    ctr: number
    cumulativeClicks: number[]
    cumulativeImpressions: number[]
    diffWpdClicks: number
    diffWpdCpc: number
    diffWpdCtr: number
    diffWpdImpressions: number
    expectedClicks: number
    expectedClicksArray: number[]
    expectedImpressions: number[]
    impressions: number
    lastUpdate: Date
    minTargetClicks: number
    minTargetImpressions: number
    maxTargetClicks: number
    maxTargetImpressions: number
    totalSpend: number
}
