import * as types from './types'

const initialState: types.SettingModel = {
    id: '',
    bannerMarkup: '',
    bannerView: false,
    urlShortenersList: [],
    clickBaitList: [],
    deltaTouchpointStart: 3,
    audienceSelectionLimit: 6,
    enableExtendCampaign: false,
}

const SettingsReducer = (
    state: types.SettingModel = initialState,
    action: any 
) => {
    switch (action.type) {
        case types.SET_SITE_SETTINGS: {
            const { urlShortenersList, clickBaitList } = action.payload;
            return {
                id: action.payload.id,
                bannerMarkup: action.payload.bannerMarkup || state.bannerMarkup,
                bannerView: action.payload.bannerView || state.bannerView,
                urlShortenersList: urlShortenersList.split(",").map((w: string) => new RegExp(w, "i")) || state.urlShortenersList,
                clickBaitList: clickBaitList.split(",").map((w: string) => new RegExp(w, "i")) || state.clickBaitList,
                deltaTouchpointStart: action.payload.deltaTouchpointStart || state.deltaTouchpointStart,
                audienceSelectionLimit: action.payload.audienceSelectionLimit || state.audienceSelectionLimit,
                enableExtendCampaign: action.payload.enableExtendCampaign || state.enableExtendCampaign,
            }
        }

        default:
            return state;
    }
}

export default SettingsReducer;