export type SettingModel = {
    id: string
    bannerMarkup: string
    bannerView: boolean
    clickBaitList: RegExp[]
    urlShortenersList: RegExp[]
    deltaTouchpointStart: number
    audienceSelectionLimit: number
    enableExtendCampaign: boolean
}

export const SET_SITE_SETTINGS = "SET_SITE_SETTINGS";

type setSiteSettings = {
    type: typeof SET_SITE_SETTINGS,
    payload: any
}

export type DistpatchTypes = setSiteSettings